.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.contact-container {
  padding: 0 !important;
  margin: 0 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-slider {
  height: 100vh;
  width: 100%;
}

.slider-main img {
  position: absolute;
}

.slider-item {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  z-index: 1;
}

.slider-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #333;
  top: 0;
  left: 0;
  opacity: .7;
  z-index: -1;
}

.slider-content p {
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.slider-content h2 {
  font-size: 45px;
  font-weight: 700;
  text-transform: uppercase;
}

a.slider-btn {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  background: #ff7720;
  padding: 10px 15px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 10px;
  transition: .6s;
  /* border-radius: 0 5px 0 10px; */
}

a.slider-btn:hover {
  background-color: #fff;
  color: #ff7720;
}

.slider-main img {
  height: 100%;
  object-fit: cover;
  object-position: bottom;

}

@media (max-width:600px) {

  /* .main-slider{
    height: 50vh;
  }
  .slider-item{
    height: 50vh;
  }
  .slider-item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #333;
    top: 0;
    left: 0;
    opacity: .7;
    z-index: -1;
  }
  
  .slider-content p {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  
  .slider-content h2 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
  } */
  figure img {
    height: 500px !important;
    width: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
  }


  figure {
    display: grid !important;
    /* grid-auto-flow: column; */
    border-radius: 1rem !important;
    overflow: hidden !important;
    height: 450px !important;
    width: 80% !important;
    /* margin: 0px 10px 10px; */
  }
}

/* Header */
html,
body {
  margin: 0;
  height: 100vh;
  width: 100%;
  /* overflow: hidden; */
}

body {
  font-family: "Roboto", sans-serif;
}

.center {
  text-align: center;
}

.nav-header {
  display: flex !important;
  justify-content: center;
}

.nav-wrapper {
  display: flex;
  position: fixed;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 10px; */
  width: 95%;
  height: 80px;
  border-radius: 0 0 15px 15px;
  padding: 0 15px;
  z-index: 999;
  /* background: rgba(245, 144, 42, 0.708); */
  background-color: orange;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  /* margin-bottom: -80px; */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 60px;
}

.snav-tabs {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  list-style: none;
}

.snav-tab:not(:last-child) {
  padding: 10px 25px;
  margin: 0;
  border-right: 1px solid #eee;
}

.snav-tab:last-child {
  padding: 10px 0 0 25px;
}

.snav-tab,
.hmenu-btn {
  cursor: pointer;
}

.snav-tab a {
  text-decoration: none;
  color: #000;
}

.hidden {
  display: none;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: -25px !important;
  position: relative;
  z-index: 2;

}

@media screen and (max-width: 800px) {
  .nav-container {
    position: fixed;
    display: none;
    overflow-y: auto;
    z-index: -1;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    background: #fff;
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
  }

  .snav-tabs {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 80px;
    width: 100%;
  }

  .snav-tab:not(:last-child) {
    padding: 0px;
    margin: 15px 25px;
    border-right: unset;
    border-bottom: 1px solid #f5f5f5;
  }

  .snav-tab:last-child {
    padding: 15px 25px;
  }

  .hmenu-btn {
    position: relative;
    /* display: block; */
    margin-top: 25px;
    width: 30px;
    height: 40px;
    cursor: pointer;
    z-index: 2;
    /* padding: 10px; */
    border-radius: 10px;
  }

  .hmenu-btn .hmenu {
    display: block;
    width: 25px;
    height: 5px;
    border-radius: 2px;
    background: #111;
  }

  .hmenu-btn .hmenu:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
  }

  .hmenu-btn .hmenu:nth-child(3) {
    margin-top: 4px;
  }

  #hmenuToggle:checked+.hmenu-btn .hmenu {
    transition: transform 0.2s ease;
  }

  #hmenuToggle:checked+.hmenu-btn .hmenu:nth-child(1) {
    transform: translate3d(0, 6px, 0) rotate(45deg);
    width: 30px;
  }

  #hmenuToggle:checked+.hmenu-btn .hmenu:nth-child(2) {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }

  #hmenuToggle:checked+.hmenu-btn .hmenu:nth-child(3) {
    transform: translate3d(0, -12px, 0) rotate(-45deg);
    width: 30px;
  }

  #hmenuToggle:checked~.nav-container {
    z-index: 1;
    display: flex;
    animation: hmenu-slide-left 0.3s ease;
    background: #fff;
    /* Semi-transparent background */
    backdrop-filter: blur(10px);
  }

  @keyframes hmenu-slide-left {
    0% {
      transform: translateX(200px);
    }

    to {
      transform: translateX(0);
    }
  }
}


/* contact */
.contact-map {
  margin: 10px;
}

.contact-banner {
  padding: 0 !important;
  background: url('https://images.unsplash.com/photo-1553267574-277716d448fb?q=80&w=1778&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.section-header {
  background: #f07e3c3c;
  height: 50vh;
  text-align: center;
  display: grid;
  align-items: center;
}

.about-container {
  margin-top: 50px;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
}

.section-header p {
  color: #FFF;
}

.contact-form {
  background-color: #fff;
  /* padding: 40px; */
  width: 80%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input[type="submit"] {
  width: 100%;
  background: #ff7720;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ff7720;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
  background: #FFF;
  color: #ff7720;
}

/* About */
.about-banner {
  background: url('https://images.unsplash.com/photo-1587858615822-013485d327d3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.about-title {
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 5px;
  color: rgb(237 123 51)
    /* font-family: "Bungee Spice", sans-serif */
}

.about-image {
  background-position: center;
  background-size: cover;
}

.about-image img {
  height: auto;
  width: 70%;
}

.about-details-title {
  color: rgb(255, 119, 32);
  font-size: 40px;
  font-weight: 600;
}

.about-details-desc {
  font-size: 25px;
  margin-top: 20px;
}

.history-info a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.history-info a:hover {
  transition: all 0.5s ease-in-out;
}

.history-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 900px;
}

.history-info h4 {
  font-size: 30px;
  font-weight: 700;
  color: #ff7720;
}

@media screen and (max-width: 860px) {
  .we-are-block {
    height: 2200px;
  }
}

@media screen and (max-width: 500px) {
  .we-are-block {
    height: 2300px;
  }

  .section-header h2 {
    color: #FFF;
    font-weight: bold;
    font-size: 2em;
  }
}

#about-us-section {
  background: #ff7720;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 860px) {
  #about-us-section {
    flex-direction: column;
    justify-content: space-between;
  }

}

.about-us-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 860px) {
  .about-us-image {
    position: relative;
    width: 100%;
    height: 45%;
  }
}

@media screen and (max-width: 747px) {
  .about-us-image {
    height: 35%;
  }
}

@media screen and (max-width: 644px) {
  .about-us-image img {
    position: absolute;
    left: -220px;
  }
}

.about-us-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 40%;
  height: 80%;
  margin-right: 850px;
  margin-left: 12px;
  z-index: 2;
}

@media screen and (max-width: 1353px) {
  .about-us-info {
    margin-right: 400px;
    width: 60%;
    background: #ff772099;
    padding: 0px 25px 0px 0px;
  }
}

@media screen and (max-width: 1238px) {
  .about-us-info {
    margin-right: 340px;
    width: 100%;
  }
}

@media screen and (max-width: 1111px) {
  .about-us-info {
    margin-right: 270px;
  }
}

@media screen and (max-width: 910px) {
  .about-us-info {
    margin-right: 150px;
  }
}

@media screen and (max-width: 860px) {
  .about-us-info {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 20px 0px 20px !important;
    width: 100%;
    height: 55%;
    align-items: center;
  }
}

@media screen and (max-width: 747px) {
  .about-us-info {
    height: 65%;
  }
}

.about-us-info h2 {
  color: white;
  font-size: 40pt;
  text-align: right;
}

@media screen and (max-width: 860px) {
  .about-us-info h2 {
    text-align: center;
  }
}

.about-us-info p {
  color: white;
  font-size: 14pt;
  text-align: right;
}

@media screen and (max-width: 860px) {
  .about-us-info p {
    text-align: center;
  }
}

.about-us-info a {
  background-color: white;
  color: #ff7720;
  width: 180px;
  text-align: center;
  padding: 15px 0px 15px 0px;
  font-size: 14pt;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.about-us-info a:hover {
  background: #404140;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: translateY(10px);
}

#history-section {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 860px) {
  #history-section {
    flex-direction: column;
    justify-content: space-between;
  }
}

.history-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 820px;
  height: 100%;
  overflow: hidden;
}



@media screen and (max-width: 860px) {
  .history-image {
    position: relative;
    width: 100%;
    height: 40%;
  }
}

@media screen and (max-width: 747px) {
  .history-image {
    height: 35%;
  }

  figure {
    margin: 0 0 1rem 2.2rem !important;
  }
}

.product-info-content {
  margin: 0;
  display: block;
  /* Ensures block-level behavior */
  text-align: center;
}

@media screen and (max-width: 644px) {
  .history-image img {
    position: relative;
    right: 0px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 300px;
  }

  figure img {
    /* height: 380px !important; */
    width: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
  }

  figure {
    /* margin: 0 0 1rem 1.2em !important; */
    display: grid !important;
    /* grid-auto-flow: column; */
    border-radius: 1rem !important;
    overflow: hidden !important;
    height: 450px !important;
    width: 80% !important;
    /* margin: 0px 10px 10px; */
  }
}


.nav-links li a {
  text-decoration: none;
}

.history-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 40%;
  height: 80%;
  margin-left: 850px;
  margin-right: 12px;
  z-index: 2;
}

@media screen and (max-width: 1353px) {
  .history-info {
    margin-left: 400px;
    width: 60%;
    background: #ffffff99;
    padding: 0px 0px 0px 25px;
  }
}

@media screen and (max-width: 1238px) {
  .history-info {
    margin-left: 340px;
    width: 100%;
  }
}

@media screen and (max-width: 1111px) {
  .history-info {
    margin-left: 270px;
  }
}

@media screen and (max-width: 910px) {
  .history-info {
    margin-left: 150px;
  }
}

@media screen and (max-width: 860px) {
  .history-info {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 40px 0px 40px !important;
    width: 100%;
    height: 60%;
    align-items: center;
  }
}

@media screen and (max-width: 747px) {
  .history-info {
    height: 65%;
  }
}

.history-info h2 {
  color: #ff7720;
  font-size: 40pt;
  text-align: left;
}

@media screen and (max-width: 860px) {
  .history-info h2 {
    text-align: center;
  }
}

.history-info p {
  /* color: #ff7720; */
  font-size: 14pt;
  text-align: left;
}

@media screen and (max-width: 860px) {
  .history-info p {
    text-align: center;
  }
}

.history-info a {
  background-color: #ff7720;
  color: white;
  width: 180px;
  text-align: center;
  padding: 15px 0px 15px 0px;
  font-size: 14pt;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.history-info a:hover {
  background: #404140;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: translateY(10px);
}

/* Footer */
ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta {
  display: flex;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

/* Home */
.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  /* border-radius: 40px; */
  /* box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22); */
  cursor: pointer;
  transition: 0.4s;
}

.card .card_image {
  width: inherit;
  height: inherit;
  /* border-radius: 40px; */
}

.card .card_image img {
  width: inherit;
  height: 300px;
  /* border-radius: 40px; */
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -80px;
  height: 40px;
}

.card:hover {
  transform: scale(0.9, 0.9);
  /* box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
    -5px -5px 30px 15px rgba(0,0,0,0.22); */
}

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }

  .footer-social-icon {
    margin-bottom: 2rem;
  }
}


/*
.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  transition: 0.4s;
}
*/

/* home */
.intro {
  height: 500px;
  display: block;
}

.intro-details span {
  font-family: cursive;
  font-size: 20px;
  font-weight: 600;
}

/* catalog */
.catalog-wrapper h1,
.catalog-wrapper h2 {
  font-family: 'Fjalla One', sans-serif;
  margin-top: 0;
  text-transform: uppercase;
}

.catalog-wrapper figure {
  margin: 0;
}

.catalog-wrapper img {
  height: auto;
  width: 100%;
}

.catalog-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: minmax(100px, auto);
  margin: 24px;
  position: relative;
}

.catalog-wrapper>* {
  grid-column: 1 / span 2;
}

.catalog-wrapper .header {
  font-size: 1.5em;
  grid-row: 1 / 2;
}

.catalog-wrapper header:after {
  background: #ff7720;
  content: "";
  display: block;
  height: 20px;
  width: auto;
}

.catalog-wrapper .article {
  text-align: right;
}

.catalog-wrapper .featured-image-1 {
  grid-row: 2 / 3;
}

.number-1 {
  grid-row: 2 / 4;
}

.catalog-wrapper .featured-image-2,
.number-2 {
  grid-row: 4 / 5;
}

.number-1,
.number-4 {
  align-self: end;
  color: #ff752072;
  font-family: 'Fjalla One', sans-serif;
  font-size: 480px;
  line-height: 480px;
  grid-column: 1 / 2;
}

.catalog-wrapper .article-1,
.catalog-wrapper .article-4 {
  grid-column: 2 / 3;
}

.catalog-wrapper .featured-image-2,
.number-2 {
  align-self: end;
}

.number-2 {
  color: #eee;
  font-size: 200px;
  justify-self: end;
  line-height: 160px;
}

.number-3 {
  background: #ff7720;
  border-radius: 130px;
  color: white;
  font-family: 'Fjalla One', sans-serif;
  font-size: 100px;
  height: 130px;
  line-height: 130px;
  position: absolute;
  grid-row: 6 / 7;
  right: -20px;
  text-align: center;
  top: -20px;
  width: 130px;
}

.catalog-wrapper .featured-image-3 img {
  height: 100%;
}

.number-4 {
  font-size: 320px;
  left: -20px;
  line-height: 320px;
  position: absolute;
}

@media (min-width: 600px) {

  .catalog-wrapper {
    grid-template-columns: repeat(2, 1fr 2fr);
    margin: 120px auto;
    width: 90%;
  }

  .catalog-wrapper .header {
    grid-column: 3 / 5;
    width: 70%;
  }

  .catalog-wrapper .featured-image-1 {
    grid-row: 1 / 3;
  }

  .catalog-wrapper .article-1 {
    grid-row: 3 / 4;
  }

  .catalog-wrapper .article-2 {
    grid-column: 4 / 5;
    grid-row: 4 / 5;
  }

  .catalog-wrapper .featured-image-2,
  .number-2 {
    grid-column: 3 / 5;
    grid-row: 2 / 4;
  }

  .catalog-wrapper .featured-image-3,
  .number-3 {
    grid-row: 4 / 6;
  }

  .catalog-wrapper .featured-image-4,
  .number-4 {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
  }

  .number-4 {
    font-size: 320px;
    grid-row: 5 / 7;
  }

  .catalog-wrapper .article-3,
  .catalog-wrapper .article-4 {
    grid-row: 6 / 7;
  }

  .catalog-wrapper .article-4 {
    grid-column: 4 / 5;
  }

}

@media (max-width: 600px) {

  .catalog-wrapper {
    margin: 120px auto;
  }

  .catalog-wrapper h1,
  .catalog-wrapper h2 {
    font-size: 1em;
    padding: 0 10px;
  }

  .number-1,
  .number-4 {
    font-size: 200px;
    line-height: 480px;
  }

  .number-3 {
    border-radius: 130px;
    font-size: 80px;
    height: 100px;
    line-height: 100px;
    right: 10px;
    top: -50px;
    width: 100px;
  }

  .number-4 {
    left: 10px;
  }
  .catalog-wrapper .article {
   padding: 0 10px;
  }
}

/* -----------------------Product Detail-------------------- */
.productDetail{
  margin: 150px 50px;
  min-height: 70vh;
}
.productDetail .product-details{
  position: relative;
  width: 70%;
  background: #ff7520b9;
  padding: 60px;
  top: 200px;
  float: right;
  line-height: 2;
  border-radius: 60px 0 60px 0;
}
.productDetail .product-details h1{
  color: #333;
  font-weight: bold;
}
.productDetail .product-details p{
  color: #fff;
}
.productDetail .image-container{
  position: absolute;
}
.productDetail .image-container img{
  width: 54%;
  border-radius: 0 60px 0 60px;
}
@media (max-width: 650px) {
  .productDetail{
    margin: 100px 20px;
    min-height: 70vh;
  }
  .productDetail .image-container{
    position: relative;  }
  .productDetail .image-container img{
    width: 100%;
    border-radius: 0;
  }
  .productDetail .product-details{
    position: relative;
    width: 100%;
    background: orange;
    padding: 20px;
    text-align: justify;
    top: 0;
    float: none;
    line-height: 2;
    border-radius: 0;
  }
}