.experince-header {
    text-align: center;
    color: #ff7720;
    position: relative;
    font-size: 3rem;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 50px 50px;
  }
  
  .experince-header-set {
    padding: 10px;
    background: #fff;
  }
  
  .experince-header::before {
    content: "";
    position: absolute;
    display: flex;
    top: 50%;
    left: 0;
    /* background: #ff7720; */
    height: 3%;
    border-top: #ff7720 double 4px;
    width: 100%;
    z-index: -1;
  }
  
  .product-grid {
    margin-bottom: 100px;
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    column-gap: 4rem;
  }
  
  .product-grid figure {
    display: grid;
    border-radius: 1rem;
    overflow: hidden;
  
    height: 500px;
    width: 400px;
  }
  
  .product-grid figure>* {
    grid-area: 1/1;
    transition: .4s;
  }
  
  .product-grid figure figcaption {
    /* display: grid; */
    font-family: sans-serif;
    font-size: 2.3rem;
    font-weight: bold;
    color: #ff7720;
    padding: 1rem;
    background: var(--c, #ffffffb2);
    clip-path: inset(0 var(--_i, 100%) 0 0);
    -webkit-mask:
      linear-gradient(#fff 0 0),
      linear-gradient(#fff 1 0);
    -webkit-mask-composite: xor;
    -webkit-mask-clip: text, padding-box;
    margin: -1px;
  }
  
  .product-grid figure figcaption p {
    font-size: 18px;
    margin-top: 30px;
    color: #494949;
    line-height: 1.5;
  }
  
  .product-grid figure img {
    height: 500px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .product-grid figure:hover figcaption {
    --_i: 0%;
  }
  
  .product-grid figure:hover img {
    transform: scale(1.2);
  }
  /* Whatsapp */
  /* .whatsapp-button {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 1;
  } */
  .btn-whatsapp-pulse {
	background: #ffa500;
	color: white;
	position: fixed;
	bottom: 30px;
	left: 30px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 35px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
    z-index: 1;
}
.btn-whatsapp-pulse img{
    height: 70px;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(237, 170, 36, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
	bottom: 120px;
	right: 20px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #ffa500;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}
.ellips{
  display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

  
  @supports not (-webkit-mask-clip: text) {
    .product-grid figure figcaption {
      -webkit-mask: none;
      color: #fff;
    }
  }
  
  @media (max-width:600px) {
    .product-grid {
      margin-bottom: 0px;
      display: grid;
      grid-auto-flow: row;
    }
  
    .product-container {
      margin-bottom: 100px;
    }
  
    .experince-header {
      text-align: right;
      font-size: 2.2rem;
      margin: 50px;
    }
  }